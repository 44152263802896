.cont--nav-button {
    display: inline-block;
    background: linear-gradient(175deg, #88E5B8, #98E588);
    padding: 0.4rem 0.5rem;
    border: 1px solid #88E5B8;
    border-radius: 4px;
    transition: all 0.1s ease-in-out;
}

.cont--nav-button:hover {
    box-shadow: 0px 0px 10px #98E588;
}

.a--nav-button * {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    color: black;
}