.cont--featured-projects {
    height: 1800px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
}


.line {
    height: 1245px;
    background-color: #A4A2FF;
    grid-row: 1 / 4;
    transition: height 0.2s ease-out;
}

.span-topline {
    color: #A4A2FF;
    display: block;
    margin-left: 16px;
}

.cont--featured-project-1 {
    grid-row: 1 / 2;
}

.cont--featured-project-2 {
    grid-row: 2 / 3;
}

.cont--featured-project-3 {
    grid-row: 3 / 4;
}

.cont--featured-project {
    grid-column: 2 / 3;
    transition: all 0.7s ease-in-out;
}

.cont--featured-projects-title {
    display: flex;
    align-items: center;
}

.dash {
    position: relative;
    height: 2rem;
    width: 10px;
    margin-right: 6px;
}

.dash::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 2px;
    background-color: #A4A2FF;
}

.cont--featured-project-tags {
    margin-left: 16px;
}

.cont--featured-projects-links {
    margin-left: 16px;
    margin-right: 8px;
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.embed {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 4px;
    aspect-ratio: 16/9;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    z-index: 0;

}

.filter--embed-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    z-index: 0;
}

.cont--embed-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

@container resize-box (aspect-ratio > 16/9) {
    .embed {
        width: auto;
        height: 100%;
    }
}

.visible {
    opacity: 100%;
    transform: translateX(0);
}


.hidden-left {
    opacity: 0;
    transform: translateX(50px);
}

.hidden {
    opacity: 0;
}

/* See index.html
*/
/* @supports (animation-timeline: view()) {
    .cont--image-column {
        transform: translateY(-220px);
        animation: parallax linear;
        animation-timeline: view();
    }

    @keyframes parallax {
        to {
            transform: translateY(220px);
        }
    }

    .embed--image-1 {
        margin-top: 9rem !important;
    }

    .embed--image-3 {
        margin-top: 0 !important;
    }
} */



@media screen and (min-width: 800px) {
    .cont--featured-projects-column {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: 1px 1fr;
        grid-template-rows: repeat(3, 1fr);
        z-index: 3;
        /* Spans across the first two columns */
    }

    .cont--two-thirds-width {
        width: 66%;
        display: flex;
        flex-direction: column;
    }

    .cont--image-column {
        grid-column: 3 / 7;
        grid-row: 1 / 2;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        z-index: 0;
    }

    .cont--image {
        container-type: size;
        container-name: resize-box;
        display: grid;
        resize: none;
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: all 1s ease-in-out 0.7s;
        will-change: transform;
    }


    .embed {
        margin-top: 4.5rem;
    }
}



@media screen and (min-width: 600px) and (max-width: 800px) {
    .cont--featured-projects-column {
        grid-column: 1 / 5;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: 1px 1fr;
        grid-template-rows: repeat(3, 1fr);
        z-index: 3;
    }

    .cont--two-thirds-width {
        width: 75%;
        display: flex;
        flex-direction: column;
    }

    .cont--image-column {
        grid-column: 4 / 7;
        grid-row: 1 / 2;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        z-index: 0;
    }

    .cont--image {
        container-type: size;
        container-name: resize-box;
        display: grid;
        resize: none;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }


    .embed {
        margin-top: 4.5rem;
    }
}


@media screen and (max-width: 600px) {
    .cont--featured-projects-column {
        grid-column: 1 / 7;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: 1px 1fr;
        grid-template-rows: repeat(3, 1fr);
        z-index: 3;
    }

    .cont--two-thirds-width {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .cont--featured-projects-links {
        margin-right: 0;
    }


    .cont--image-column {
        display: none;
    }

    .smaller-screenshot {
        margin-left: 1rem;
        margin-top: 1rem;
        width: calc(100% - 1rem);
        height: auto;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
        border: 1px solid #10244B;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}