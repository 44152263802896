ul {
    margin: 0;
}

.cont--navbar * {
    user-select: none;
}

.fade-in--0 {
    opacity: 0;
    animation: fadeIn 0.8s ease-in forwards 3s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        /* Start with opacity 0 */
    }

    to {
        opacity: 1;
        /* End with opacity 1 */
    }
}


.nav-resume {
    border-radius: 4px;
    padding: 2px 6px;
    color: rgba(5, 4, 18, 1) !important;
    background-color: #D6D6FF;
}

.nav-resume:hover {
    background-color: #98E588;
}

@media only screen and (max-width: 700px) {
    .cont--navbar * {
        color: #D6D6FF;
        font-family: 'Lato', sans-serif;
        font-size: 10px;
    }

    nav {
        position: fixed;
        margin: 0;
        top: 0;
        padding-top: 2px;
        width: 100%;
        background: rgba(5, 4, 18, .85);
        box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(8px);
        z-index: 20;
    }

    .flexbox {
        margin: 0 1rem;
        padding-right: 4px;
        padding-top: 5px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: #D6D6FF solid 1px;
    }

    .star {
        width: 24px;
        height: 24px;
    }

    .name-container {
        display: flex;
        align-items: center;
    }

    li {
        display: inline;
        margin-left: 8px;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .a-highlight {
        color: #ED7EFF;
    }


    a:hover {
        color: #98E588;

    }
}

/* @media only screen and (min-width: 670px) and (max-width: 900px) {
    * {
        color:  #D6D6FF;
        font-family: 'Roboto Mono', monospace;
        font-size: 20px;
        margin: 0;
        padding: 0;
    }

    nav {
        position: fixed;
        top: 0;
        width: 100%;
        background: linear-gradient(168deg, rgba(0, 0, 0, 0.80) 4.22%, rgba(0, 0, 0, 0.60) 93.29%);
        box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(8px);
        z-index: 2;
    }

    .flexbox {
        margin: 0px 15px;
        padding-top: 5px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom:  #D6D6FF solid 1px;
    }

    .star {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }

    .name-container {
        display: flex;
        align-items: center;
    }

    li {
        display: inline;
        margin-left: 15px;
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    a:hover {
        color: #98E588;
        transition: all 0.2s ease-in-out;
    }
} */


@media only screen and (min-width: 700px) {
    .cont--navbar * {
        color: #D6D6FF;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
    }

    nav {
        position: fixed;
        margin: 0;
        top: 0;
        padding-top: 2px;
        width: 100%;
        background: rgba(5, 4, 18, .85);
        box-shadow: 0px 4px 26px -1px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(8px);
        z-index: 20;
    }

    .flexbox {
        margin: 0 1rem;
        padding-right: 6px;
        padding-top: 5px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: #D6D6FF solid 1px;
    }

    .star {
        width: 30px;
        height: 30px;
    }

    .name-container {
        display: flex;
        align-items: center;
    }

    li {
        display: inline;
        margin-left: 15px;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .a-highlight {
        color: #ED7EFF;
    }

    a:hover {
        color: #98E588;
        transition: all 0.2s ease-in-out;
    }
}