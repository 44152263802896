.img--link-icon {
    width: 24px;
    height: 24px;
    transition: all 0.1s ease-in-out;
    transform: translateY(1px);
    user-select: none;
    cursor: pointer;
}

.img--link-icon:hover {
    width: 26px;
    height: 26px;
    transform: translate(-1px, 1px);
}