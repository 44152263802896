.cont--desc-featured-projects {
    margin-left: 16px;
    margin-top: 0.5rem;
    background: linear-gradient(175deg, rgba(18, 10, 48, 1), rgba(10, 23, 48, 1));
    border-radius: 4px;
    border: 1px solid #0A1730;
    box-shadow: 2px 2px 4px #050412;
}

.desc-featured-projects {
    color: #D6D6FF;
    margin: 0.5rem 0.75rem;
    font-size: 1rem;
}