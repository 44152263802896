.cont--smaller-tags {
    margin-top: 0.5rem;
}

.cont--smaller-tag {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.smaller-tag {
    background: linear-gradient(175deg, #ED7EFF, #C67EFF);
    margin-right: 0.4rem;
    padding: 0.1rem 0.4rem;
    border-radius: 20px;
    color: #120A30;
    border: 1px solid #C67EFF;
    font-size: 12px;
}