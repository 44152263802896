.cont--hero {
    height: 100vh;
    min-height: 500px;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cont--hero-inner {
    margin-left: 1rem;
    padding-bottom: calc(2rem + calc(100vh - min(100dvh, 100vh)));
    width: 100%;
    transition: all 1s ease-in-out;
}

.cont--hero h1,
.cont--hero h3,
.cont--hero span {
    text-shadow:
        -2px -2px 0 black,
        /* Top left */
        2px -2px 0 black,
        /* Top right */
        -2px 2px 0 black,
        /* Bottom left */
        2px 2px 0 black;
}

.h3--creative {
    margin-left: 3px;
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
}

.h3--welcome {
    margin-left: 3px;
    font-family: "Lato", sans-serif;
}

.right-align {
    text-align: right;
}

@media screen and (min-width: 700px) {
    .h1--hero {
        font-family: "Lato", sans-serif;
        line-height: 5rem;
        font-size: 5rem;
    }
}

@media screen and (max-width: 700px) {
    .h1--hero {
        font-family: "Lato", sans-serif;
        line-height: 15vw;
        font-size: 15vw;
    }
}

.h1--hero span {
    line-height: 5rem;
    font-size: 5rem;
}

.mark--hero {
    font-family: "Cinzel", serif;
    font-weight: 500;
    color: #A4A2FF;

}

.cont--hero-inner * {
    user-select: none;
}

.fade-in--1,
.fade-in--2,
.fade-in--3,
.fade-in--4,
.fade-in--5,
.fade-in--6,
.fade-in--7 {
    opacity: 0;
}

.fade-in--1 {
    animation: fadeIn 0.8s ease-in forwards 4.25s;
}

.fade-in--2 {
    animation: fadeIn 0.8s ease-in forwards 5s;
}

.fade-in--3 {
    animation: fadeIn 0.8s ease-in forwards 5.75s;
}

.fade-in--4 {
    animation: fadeIn 0.8s ease-in forwards 7.5s;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        /* Start with opacity 0 */
    }

    to {
        opacity: 1;
        /* End with opacity 1 */
    }
}