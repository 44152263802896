.upper-line {
    margin-top: 0.25rem;
    border-top: 2px solid #A4A2FF;
    padding-top: 1rem;
}

.max-blog-width {
    margin: 0 auto;
    padding: 1rem;
    max-width: 800px;
}

.title--blog {
    line-height: 3.4rem;
}

.cont--blog-post>p {
    margin: 10px 0;
    font-size: 18px;
}

.cont--blog-post>h2 {
    border-bottom: 1px solid #464558;
}

.cont--blog-post>h3 {
    color: #A4A2FF;
}

.cont--blog-post a {
    color: #98E588;
    text-decoration: underline;
}

.cont--blog-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
}

.full-width-img {
    width: 100%;
}

.medium-width-img {
    width: 75%;
}

.small-width-img {
    width: 50%;
}

.lightbox-img {
    cursor: pointer;
}

.span--image-caption {
    margin-top: 2px;
    color: #A4A2FF;
    font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
    .medium-width-img {
        width: 100%;
    }

    .small-width-img {
        width: 75%;
    }

}


.gallery-container {
    width: 100%;
}

.pixelated {
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}