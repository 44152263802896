.cont--project-card {
    width: 100%;
    height: auto;
    aspect-ratio: 25 / 35;
    perspective: 3000px;
    transition: opacity 0.6s;
    opacity: 0%;
}

.opaque-card {
    opacity: 100%;
}

.cont-inner--project-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s ease-out 0.6s;
    transform-style: preserve-3d;
    background: linear-gradient(160deg, #120a30, #0a1730);
}

.flipped {
    transform: rotateY(180deg);
}

.cont-inner--project-card-back,
.cont-inner--project-card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: inset 0 0 0 1px #0A1730;
}

.cont-inner--project-card-back {
    background: linear-gradient(160deg, rgba(18, 10, 48, 1), rgba(10, 23, 48, 1));
}

.cont-inner--project-card-front {
    background: linear-gradient(160deg, rgba(18, 10, 48, 1), rgba(10, 23, 48, 1));
    transform: rotateY(180deg);
}

.cont-inner-cont--project-card-front {
    padding: 0.7rem 0.75rem;
    height: calc(100% - 1.4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cont--other-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
}

.span--show-more {
    color: #98E588;
}

.cont--show-more {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cont--action-button-extra-margin {
    margin-top: 0.6rem;
}

.span--project-card-subtitle {
    color: #A4A2FF;
    font-size: 14px;
}

.h4--project-card-title {
    line-height: 1.7rem;
}

.p--description {
    color: #D6D6FF;
    margin: 0;
    font-size: 14px;
}

.cont--project-card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.wrapr--learn-more {
    margin-bottom: 1px;
}

@media screen and (min-width: 900px) {
    .cont--other-projects-cards {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        padding-top: 1rem;
        margin: 0rem 0 0.5rem;
    }

    .cont--other-projects-cards-hidden {
        width: 100%;
        height: auto;
        aspect-ratio: 110 / 38;
        overflow: hidden;
    }

    .cont-inner--project-card,
    .cont-inner--project-card-back,
    .cont-inner--project-card-front {
        border-radius: 0.8rem;
    }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
    .cont--other-projects-cards {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        padding-top: 1rem;
        margin: 0rem 0 0.5rem;
    }

    .cont--other-projects-cards-hidden {
        width: 100%;
        height: auto;
        aspect-ratio: 82 / 38;
        overflow: hidden;
    }

    .cont-inner--project-card,
    .cont-inner--project-card-back,
    .cont-inner--project-card-front {
        border-radius: 0.8rem;
    }
}

@media screen and (min-width: 480px) and (max-width: 700px) {
    .cont--other-projects-cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding-top: 1rem;
        margin: 0rem 0 0.5rem;
    }

    .cont--other-projects-cards-hidden {
        width: 100%;
        height: auto;
        aspect-ratio: 25 / 35;
        overflow: hidden;
    }

    .cont-inner--project-card,
    .cont-inner--project-card-back,
    .cont-inner--project-card-front {
        border-radius: 0.8rem;
    }
}


@media screen and (min-width: 200px) and (max-width: 480px) {
    .cont--other-projects-cards {
        max-width: 280px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        padding-top: 1rem;
        margin: 0rem 0 0.5rem;
    }

    .cont--other-projects-cards-hidden {
        width: 100%;
        height: auto;
        aspect-ratio: 25 / 72;
        overflow: hidden;
    }

    .cont-inner--project-card,
    .cont-inner--project-card-back,
    .cont-inner--project-card-front {
        border-radius: 0.8rem;
    }
}

@media screen and (max-width: 200px) {
    .cont--other-projects-cards {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        padding-top: 1rem;
        margin: 0rem 0 0.5rem;
    }

    .cont--other-projects-cards-hidden {
        width: 100%;
        height: auto;
        aspect-ratio: 25 / 74;
        overflow: hidden;
    }

    .cont-inner--project-card,
    .cont-inner--project-card-back,
    .cont-inner--project-card-front {
        border-radius: 0.8rem;
    }
}