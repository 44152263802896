.cont--about-me {
    margin-top: 1.2rem;
    opacity: 0;
    transition: opacity 1s ease-in;
}

.cont--about-me h1 {
    line-height: 2rem;
    margin-bottom: 1.5rem;
}

.visible {
    opacity: 1;
}

.skills-list>li {
    margin-left: 0;
}

.cont--about-me strong {
    color: #a4a2ff;
}

@media screen and (max-width: 700px) {
    .cont--about-me {
        margin-bottom: 8rem;
    }
}


@media screen and (min-width: 700px) {
    .cont--about-me {
        display: grid;
        grid-template-columns: 2fr 1fr;
        min-height: 20rem;
        gap: 4rem;
        margin-bottom: 12rem;
    }
}



@media screen and (min-width: 850px) {
    .cont--skills-list {
        width: 80%;
    }

    .skills-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }
}

@media screen and (max-width: 850px) {
    .cont--skills-list {
        width: 100%;
    }

    .skills-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }
}

@media screen and (min-width: 500px) and (max-width: 700px) {
    .cont--skills-list {
        width: 100%;
    }

    .skills-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }

    .cont--face-card {
        width: 66%;
        height: auto;
        aspect-ratio: 25 / 35;
        perspective: 3000px;
        transition: opacity 1s;
        opacity: 0%;
        margin: auto;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .cont--face-card {
        width: 100%;
        height: auto;
        aspect-ratio: 25 / 35;
        perspective: 3000px;
        transition: opacity 1s;
        opacity: 0%;
        margin: auto;
        margin-top: 2rem;
    }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
    .cont--skills-list {
        width: 100%;
    }

    .skills-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }
}

@media screen and (max-width: 300px) {
    .cont--skills-list {
        width: 100%;
    }

    .skills-list {
        display: grid;
        grid-template-columns: 1fr;
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }
}


/* CARD CSS*/
@media screen and (min-width: 700px) {
    .cont--face-card {
        width: 100%;
        height: auto;
        aspect-ratio: 25 / 35;
        perspective: 3000px;
        transition: opacity 1s;
        opacity: 0%;
    }


}

.opaque-card {
    opacity: 100%;
}

.cont-inner--face-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s ease-out 1s;
    transform-style: preserve-3d;
    background: linear-gradient(160deg, #120a30, #0a1730);
}

.flipped {
    transform: rotateY(180deg);
}

.cont-inner--face-card-back,
.cont-inner--face-card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: inset 0 0 0 1px #0A1730;
}

.cont-inner--face-card-back {
    background: linear-gradient(160deg, rgba(18, 10, 48, 1), rgba(10, 23, 48, 1));
}

.cont-inner--face-card-front {
    background: linear-gradient(160deg, rgba(18, 10, 48, 1), rgba(10, 23, 48, 1));
    transform: rotateY(180deg);
}

.cont-inner-cont--face-card-front {
    padding: 0.7rem 0.75rem;
    height: calc(100% - 1.4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cont-inner--face-card,
.cont-inner--face-card-back,
.cont-inner--face-card-front {
    border-radius: 1.2rem;
}

/* END CARD CSS*/

.cont--about-me p {
    margin: 0;
    padding: 0;
    line-height: 1.4rem;
}