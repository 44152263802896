body {
  background-color: #050412;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  color: #D6D6FF;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  margin: 0;
  z-index: 4;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

span,
p {
  color: #D6D6FF;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}